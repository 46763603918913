<template>
  <div ref="cropperContainer">
    <div v-if="header" class="form-buttons">
      <button
        type="button"
        class="form-button"
        @click="$emit('rotate-left')"
        :title="$t('dam.rotate_minus_90')"
      >
        <IconRotateLeft class="form-button__icon" />
        {{ $t('dam.rotate_minus_90') }}
      </button>
      <button
        type="button"
        class="form-button"
        @click="$emit('rotate-right')"
        :title="$t('dam.rotate_plus_90')"
      >
        <IconRotateRight class="form-button__icon" />
        {{ $t('dam.rotate_plus_90') }}
      </button>
      <button
        type="button"
        class="form-button"
        @click="$emit('face-detect')"
      >
        <IconFaceDetection class="form-button__icon" />
        {{ $t('dam.crop_modal_face_detect') }}
      </button>
      <button
        type="button"
        class="form-button form-button--save"
        @click="$emit('save-crop')"
        :disabled="callingAPI"
      >
        <i class="fa fa-save "></i> {{ $t('dam.crop_modal_save') }}
      </button>
      <button
        v-if="showCloseButton"
        type="button"
        class="close"
        @click="$emit('close')"
      >
        ×
      </button>
    </div>
    <template v-else>
      <app-preloader v-if="callingAPI"></app-preloader>
      <app-vue-cropper
        v-show="!callingAPI"
        :key="cropperKey"
        id="croppedImage"
        ref="cropper"
        :guides="true"
        :view-mode="1"
        drag-mode="crop"
        :auto-crop-area="1"
        :min-container-width="croppedImageWidth"
        :min-container-height="croppedImageHeight"
        :background="true"
        :rotatable="true"
        :src="assetPath"
        alt="Source Image"
        :ready="getCrop"
        center
        :aspect-ratio=getAspectRatio()
        :check-cross-origin="false"
      >
      </app-vue-cropper>
    </template>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import NotifyService from '../../../services/NotifyService'
import Preloader from '../../preloader/Preloader'
import MediaMixin from '../../mixins/Media'
import CropMixin from '../../mixins/Crop'
import DamApi from '../../../api/dam'
import IconRotateLeft from '@/assets/img/svg/rotate-left.svg?inline'
import IconRotateRight from '@/assets/img/svg/rotate-right.svg?inline'
import IconFaceDetection from '@/assets/img/svg/face-detection.svg?inline'
import { mapState } from 'vuex'

require('tracking')
require('tracking/build/data/face-min')

export default {
  name: 'AssetCropForm',
  mixins: [MediaMixin, CropMixin],
  props: {
    asset: {
      type: Object
    },
    header: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      croppedImageWidth: 200,
      croppedImageHeight: 100,
      cropperKey: 0,
      timestamp: this.getCurrentTimestamp()
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    assetPath () {
      return this.getDamImagePath(this.asset.basename) + '?' + this.timestamp
    }
  },
  components: {
    appPreloader: Preloader,
    appVueCropper: VueCropper,
    IconRotateLeft,
    IconRotateRight,
    IconFaceDetection
  },
  methods: {
    updateCropperDimensions () {
      if (this.$refs.cropperContainer) {
        const containerWidth = this.$refs.cropperContainer.offsetWidth - 30
        this.croppedImageHeight = Math.min(this.asset.dimensions.height, 480)
        this.croppedImageWidth = Math.min(Math.max(this.asset.dimensions.width, containerWidth), containerWidth)
        this.cropperKey += 1
      }
    },
    close () {
      this.$emit('close')
    },
    saveCrop () {
      const cropData = this.$refs.cropper.cropper.getData()
      const imageData = this.$refs.cropper.cropper.getImageData()
      this.cropPositionEmbed = {
        upperLeftCoords: {
          xCoord: (cropData.x / imageData.naturalWidth),
          yCoord: (cropData.y / imageData.naturalHeight)
        },
        lowerRightCoords: {
          xCoord: ((cropData.x + cropData.width) / imageData.naturalWidth),
          yCoord: ((cropData.y + cropData.height) / imageData.naturalHeight)
        }
      }
      this.$store.dispatch('dam/saveCrop', { assetId: this.asset.id, cropPositionEmbed: this.cropPositionEmbed })
        .then(() => {
          this.$emit('close')
          this.$emit('post-update', this.asset.basename)
          NotifyService.setSuccessMessage(this.$t('dam.crop_modal_was_set'))
        })
    },
    getCrop () {
      this.$store.dispatch('dam/getCrop', this.asset.id)
        .then(() => {
          this.cropPositionEmbed = this.$store.getters['dam/cropPositionEmbed']
          this.setCrop(this.cropPositionEmbed)
        })
    },
    setCrop (cropPositionEmbed) {
      const imageData = this.$refs.cropper.cropper.getImageData()
      const x = imageData.naturalWidth * cropPositionEmbed.upperLeftCoords.xCoord
      const y = imageData.naturalHeight * cropPositionEmbed.upperLeftCoords.yCoord
      const width = (imageData.naturalWidth * cropPositionEmbed.lowerRightCoords.xCoord) - x
      const height = imageData.naturalWidth / (16 / 9)
      // let height = (imageData.naturalHeight * cropPositionEmbed.lowerRightCoords.yCoord) - y
      const dataProperties = {
        x: x,
        y: y,
        width: width,
        height: height
      }
      this.$refs.cropper.cropper.setData(dataProperties)
    },
    rotate (angle) {
      this.$store.commit('TOGGLE_LOADING')
      DamApi().patch('/image/' + this.asset.id + '/rotate/' + angle)
        .then(response => {
          this.timestamp = this.getCurrentTimestamp()
          this.$refs.cropper.cropper.replace(this.assetPath)
          this.setCrop(response.data.poiCrops[0])
          this.$emit('post-update', this.asset.basename)
        })
        .catch(error => {
          console.error(error)
          NotifyService.setErrorMessage(this.$t('dam.rotate_error'))
        })
        .finally(() => this.$store.commit('TOGGLE_LOADING'))
    },
    getCurrentTimestamp () {
      return parseInt(new Date().getTime() / 1000, 10)
    }
  },
  created () {
    this.setCropperWidth(1000)
  },
  mounted () {
    if (!this.header) {
      this.updateCropperDimensions()
    }
  }
}
</script>

<style scoped lang="scss">
#croppedImage {
  text-align: center;
}
.form-buttons {
  display: flex;
  gap: rem(4px);
}
.form-button {
  @include font(700 13px "Roboto");
  border-radius: rem(6px);
  border: 1px solid #D1DBE4;
  cursor: pointer;
  background-color: #FFFFFF;
  color: #465674;
  height: rem(30px);
  width: rem(130px);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: rem(5px);
  &:hover {
    background-color: lighten(#D1DBE4, 5%);
  }
  &__icon {
    @include size(14px);
    fill: #9DAAC4;
  }
  &--save {
    width: rem(170px);
    color: #FFFFFF;
    background-color: #6599FE;
    &:hover {
      background-color: lighten(#6599FE, 5%);
    }
  }
}
</style>
