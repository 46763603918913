<template>
    <span v-if="!vlm">
        <button class="btn btn-info" @click="showDamUrlModal">
            <i class="fas fa-link"></i>
            {{ $t('buttons.upload_from_url') }}
        </button>
        <app-dam-url-modal
          v-if="damUrlModal"
          @close="closeDamUrlModal"
          @show-dam-upload-modal="showDamUploadModal"
          :urlLabel="urlLabel"
        >
        </app-dam-url-modal>
        <app-dam-upload-modal
          v-if="damUploadModal"
          :files="filesFromUrl"
          :count="count"
          @close="closeDamUploadModal"
          @set-media="setMedia"
        >
        </app-dam-upload-modal>
    </span>
</template>

<script>
import { mapGetters } from 'vuex'
import DamUploadModal from './DamUploadModal'
import DamUrlModal from './DamUrlModal'

export default {
  name: 'DamUrlButton',
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    urlLabel: {
      type: String,
      default () {
        return this.$t('modal.image_url')
      }
    }
  },
  data () {
    return {
      damUploadModal: false,
      damUrlModal: false,
      filesFromUrl: [],
      count: 0
    }
  },
  components: {
    appDamUploadModal: DamUploadModal,
    appDamUrlModal: DamUrlModal
  },
  computed: {
    ...mapGetters(['vlm'])
  },
  methods: {
    showDamUploadModal (files) {
      this.damUploadModal = true
      this.damUrlModal = false
      this.filesFromUrl = files
    },
    closeDamUploadModal () {
      this.damUploadModal = false
    },
    showDamUrlModal () {
      this.damUrlModal = true
    },
    closeDamUrlModal () {
      this.damUrlModal = false
    },
    setMedia (assets) {
      this.$emit('set-media', assets)
    }
  }
}
</script>
