<template>
  <app-modal
    :title="$t('modal.media_url_header')"
    modal-size="modal-lg"
    @close="close"
  >
    <!-- Body -->
    <template slot="body">
      <div class="row">
        <div class="col-8">
          <app-input
            v-model="search.url"
            :label="urlLabel"
            id="search_url"
          >
          </app-input>
        </div>
        <div class="col-4">
          <div class="m-t-20 text-left">
            <button
              @click="extractFromUrl"
              type="button"
              class="btn btn-info "
            >
              <i class="fa fa-check"></i> {{ $t('modal.select') }}
            </button>
          </div>
        </div>
      </div>
      <app-preloader v-if="callingAPI"></app-preloader>
    </template>
  </app-modal>
</template>

<script>
import Modal from '../shared/Modal'
import { mapState } from 'vuex'
import Input from '../form/inputs/Input'
import Preloader from '../preloader/Preloader'
import DamApi from '../../api/dam'

export default {
  name: 'DamUrlModal',
  props: {
    urlLabel: {
      type: String
    }
  },
  data () {
    return {
      search: {
        url: ''
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ])
  },
  components: {
    appModal: Modal,
    appInput: Input,
    appPreloader: Preloader
  },
  methods: {
    close () {
      this.$emit('close')
    },
    extractFromUrl () {
      this.$store.commit('TOGGLE_LOADING')
      DamApi().post('/metadata/image/url', { url: this.search.url })
        .then((response) => {
          const files = []
          files.push({ image: response.data.base64Image })
          this.$store.commit('TOGGLE_LOADING')
          this.$emit('show-dam-upload-modal', files)
        })
        .catch(error => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
        })
    }
  }
}
</script>
